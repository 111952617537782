import { config } from "@/lib/config"

  export function chatauthor(chat,field,bot_name=config.chat.bot.default_name) {

    let bot = {
       id: 0
      ,user_name: bot_name
      ,avatar_url:  config.chat.bot.default_image
      ,bol_nsfw:  0
      ,avatar_url_sfw:  config.chat.bot.default_image
      ,avatar_crdate: ``
      ,custom_flair: ``
      ,bol_mod: 0
    }

    let ban = {
      id: 0
     ,user_name: `[InactiveAccount]`
     ,avatar_url:  ``
     ,bol_nsfw:  0
     ,avatar_url_sfw:  ``
     ,avatar_crdate: ``
     ,custom_flair: ``
     ,bol_mod: 0
   }

    let server = {
      id: 0
     ,user_name: bot_name
     ,avatar_url:  config.chat.bot.default_image
     ,bol_nsfw:  0
     ,avatar_url_sfw: config.chat.bot.default_image
     ,avatar_crdate: ``
     ,custom_flair: ``
     ,bol_mod: 0
   }

    if (chat?.type=="message") {
      if (chat?.id > 0) return chat[field]
      if (chat?.id == 0) return ban[field]
    } else if (chat?.type=="bot") {
        return bot[field]
    } else if (chat?.type=="server") {
        return server[field]
    } else {

    //must be a user from a poorly wired up component. fix and remove this in the future
      return chat[field]
    }

   
  
  }