
import Image from 'next/image'

export default function ProfileContributorTag({
   showReminder=false
  ,size=16
}) {

    return (<>
               
                    
                   
                
                  <div className={`flex-0 justify-left text-sm  `}>
                    <div className="flex-0  mr-1">
                      <Image
                        width={size}
                        height={size}
                        
                        src={`/images/contributor/${showReminder ? "reminder" : "dark"}-64.svg`}
                        title={'Contributor'}
                      />
                      
                    </div>
                  </div>

            
            </>)
}